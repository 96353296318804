<template>
<div class="main">
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "userName",
            title: "用户账号",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入用户账号"
              }
            }
          },
          {
            field: "browser",
            title: "浏览器类型",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入浏览器类型"
              }
            }
          },
          {
            field: "duration",
            title: "停留时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "integer",
                min: 0,
                max: 999999,
                maxlength: 24,
                placeholder: "请输入停留时间"
              }
            }
          },
          {
            field: "id",
            title: "访问ID",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "integer",
                min: 0,
                max: 999999,
                maxlength: 24,
                placeholder: "请输入访问ID"
              }
            }
          },
          {
            field: "ipAddr",
            title: "登录IP地址",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入登录IP地址"
              }
            }
          },
          {
            field: "loginTime",
            title: "登录时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择登录时间"
              }
            }
          },
          {
            field: "logoutTime",
            title: "退出时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择退出时间"
              }
            }
          },
          {
            field: "message",
            title: "提示消息",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入提示消息"
              }
            }
          },
        ],
        editConfig: {
          enabled: false
        },
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [],
        //表单校验规则
        rules: {},
        // 新增功能字段初始化
        data: {
          enabled: "1",
          browser: "",
          duration: "",
          id: "",
          ipAddr: "",
          loginTime: "",
          logoutTime: "",
          message: "",
          osName: "",
          status: "",
          userName: ""
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [

        ],
        data: {},
      },
    };
  },

  methods: {

  },
};
</script>

<style lang="scss" scoped></style>
